function createObject() {
  let example = {
    id: "Gruppen_Subtraktion",
    showGroupNames: true,
    checkAfterHowManyItems: 2,
    checkAfterExactItemCount: true,
    workOrder:
      "Auch Subtraktionsaufgaben lassen sich, wie die Additionsaufgaben, durch Rechenstrategien leichter ausrechnen.  Welche Rechenstrategien werden in den folgenden Beispielen abgebildet? Ordnen Sie jeweils zwei Beispiele einer Rechenstrategie zu.",
    possibleCategories: [
      {
        id: 1,
        name: "Analogieaufgaben",
      },
      {
        id: 2,
        name: "Nachbaraufgaben",
      },
      {
        id: 3,
        name: "Schrittweise Rechnen",
      },
      {
        id: 4,
        name: "Umkehraufgaben",
      },
    ],
    elements: [
      {
        id: 1,
        image: require("@/assets/nzr/Gruppen/Subtraktion/01.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 2,
        image: require("@/assets/nzr/Gruppen/Subtraktion/02.png"),
        category: 1,
        flex: 3,
      },
      {
        id: 3,
        image: require("@/assets/nzr/Gruppen/Subtraktion/03.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 4,
        image: require("@/assets/nzr/Gruppen/Subtraktion/04.png"),
        category: 2,
        flex: 3,
      },
      {
        id: 5,
        image: require("@/assets/nzr/Gruppen/Subtraktion/05.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 6,
        image: require("@/assets/nzr/Gruppen/Subtraktion/06.png"),
        category: 3,
        flex: 3,
      },
      {
        id: 7,
        image: require("@/assets/nzr/Gruppen/Subtraktion/07.png"),
        category: 4,
        flex: 3,
      },
      {
        id: 8,
        image: require("@/assets/nzr/Gruppen/Subtraktion/08.png"),
        category: 4,
        flex: 3,
      },
    ],
  };

  const einsAnalogie =
    "Eins der Beispiele stellt die Verwendung einer Analogieaufgaben als Strategie dar. ";

  const einsNachbar =
    "Die Strategie der Nachbaraufgaben wird durch eines der Beispiele dargestellt. ";

  const einsSchritt =
    "Das Schrittweise Rechnen wird durch eine der Rechnungen beispielhaft dargestellt. ";

  const einsUmkehr =
    "In dem anderen der beiden Beispiele wird von der Umkehraufgabe zum Vereinfachen der Subtraktionsaufgabe Gebrauch gemacht. ";

  example.responses = [
    {
      id: "0002",
      category: example.possibleCategories[3],
      text:
        "Klasse! Bei beiden Beispielen wird von einer Umkehraufgabe und somit einer Additionsaufgabe Gebrauch gemacht.",
    },
    {
      id: "0011",
      text: einsSchritt + einsUmkehr,
    },
    {
      id: "0020",
      category: example.possibleCategories[2],
      text: "Korrekt! Bei beiden Beispielen wird schrittweise gerechnet.",
    },
    {
      id: "0101",
      text: einsNachbar + einsUmkehr,
    },
    {
      id: "0110",
      text: einsNachbar + einsSchritt,
    },
    {
      id: "0200",
      category: example.possibleCategories[1],
      text:
        "Sehr gut! In beiden Beispiel wird Gebrauch von der Nachbaraufgabe als Strategie gemacht.",
    },
    {
      id: "1001",
      text: einsAnalogie + einsUmkehr,
    },
    {
      id: "1010",
      text: einsAnalogie + einsSchritt,
    },
    {
      id: "1100",
      text: einsAnalogie + einsNachbar,
    },
    {
      id: "2000",
      category: example.possibleCategories[0],
      text:
        "Prima! Beide Beispiele stellen die Verwendung einer Analogieaufgabe dar, um die Subtraktionsaufgabe zu vereinfachen.",
    },
  ];

  return example;
}

export default createObject();
