function createObject() {
  let example = {
    id: "StempelnSchipper",
    workOrder:
      "<p>Lösen Sie die folgenden Aufgaben <u>ohne</u> die Buchstaben in Zahlen zu übersetzen.</p>",
    image: require("@/assets/nzr/Schipper.png"),
    elements: [
      [
        {
          question: "C + L = ? Wie lautet das Ergebnis der Aufgabe?",
          answers: [
            {
              correct: false,
              text: "C + L = Z",
              response: "Falsch",
            },
            {
              correct: false,
              text: "C + L = M",
              response: "Falsch",
            },
            {
              correct: true,
              text: "C + L = O",
              response: "Richtig",
            },
          ],
        },
      ],
      [
        {
          question:
            "Für das Lösen der Aufgabe C + L haben Sie sicherlich eine Strategie angewendet. Zur Lösung welcher Aufgabe können diese Überlegungen nun übertragen werden?",
          answers: [
            {
              correct: true,
              text: "D + K",
              response:
                "Indem der erste Summand dieser Aufgabe um einen Buchstaben größer ist, als der erste Summand in der Ausgangsaufgabe und der zweite Summand um einen Buchstaben kleiner, ergibt sich für D + K das gleiche Ergebnis O, wie bei C + L = O.",
            },
            {
              correct: false,
              text: "F + F",
              response:
                "Es besteht keine direkte Beziehung zwischen den Summanden der Ausgangsaufgabe und dieser Additionsaufgabe. Somit eignen sich F + F nicht zur Herleitung der Ergebnisses von C + L = O.",
            },
            {
              correct: false,
              text: "M + N",
              response:
                "Die Summanden dieser Aufgabe und der Ausgangsaufgabe haben keine direkte Beziehung zueinander und somit eignet sich das wissen über C + L = O nicht zur Herleitung des Ergebnisses dieser Aufgabe.",
            },
          ],
        },
        {
          question: "Welche Strategie lässt sich hier wiederfinden?",
          answers: [
            {
              correct: true,
              text:
                "Schrittweise Rechnen: Bei dieser Rechenstrategie wird der zweite Summand in Schritte zerlegt, sodass die Aufgabe vereinfacht wird und meist ein glattes Zwischenergebnis erreicht wird.",
              response:
                "Beim schrittweisen Rechnen hätte bei der Aufgabe C + L = O die hilfreiche Aufgabe wie folgt aussehen müssen: M + B = O. Der zweite Summand wäre in diesem Fall in zwei Schritte zerlegt worden, sodass die Aufgabe vereinfacht wird. Dies ist jedoch nicht der Fall in der Lösung der vorherigen Frage.",
            },
            {
              correct: false,
              text:
                "Tauschaufgaben: Folgende Rechenstrategie basiert auf dem Kommutativgesetz der Addition. Die Position der Summanden und nicht relevant für die Rechnung, somit kann die Position getauscht werden.",
              response:
                "Bei der Verwendung einer Tauschaufgabe hätte bei der Aufgabe C + L = O die hilfreiche Aufgabe wie folgt aussehen müssen: L + C = O. Die Positionen der beiden Summanden wären einfach vertauscht worden. Dies ist jedoch nicht der Fall in der Lösung der vorherigen Frage.",
            },
            {
              correct: true,
              text:
                "Gegensinniges Verändern: Hier werden die beiden Summanden um die gleiche Zahl gegensinnig verändert ohne, dass das Ergebnis sich verändert. Diese Strategie basiert auf dem Konstanzgesetz der Addition.",
              response:
                "Diese Strategie ist richtig, passt aber nicht zu der ausgewählten Aufgabe. Der erste Summand wird um A vergrößert und der zweite Summand um A verkleinert, das Ergebnis bleibt somit gleich. Zu welcher Aufgabe passt dieses Vorgehen?",
            },
          ],
        },
      ],
      [
        {
          question: "F + G = ? Welche Aufgabe hilft Ihnen, um F + G lösen zu können?",
          answers: [
            {
              correct: false,
              text: "Z - A = Y",
              response:
                "Indem der erste Summand dieser Aufgabe um einen Buchstaben größer ist, als der erste Summand in der Ausgangsaufgabe und der zweite Summand um einen Buchstaben kleiner, ergibt sich für D + K das gleiche Ergebnis O, wie bei C + L = O.",
            },
            {
              correct: true,
              text: "L + A = M",
              response:
                "Diese Aufgabe ist hilfreich, um F + G zu lösen. G ist nur A größer als F. Somit wird A von G abgezogen und man erhält F+F. F+F ergibt L. Da zuvor von dem G ein A abgezogen wurde, muss es nun wieder zum Ende hin addiert werden und man erhält die Rechnung L + A = M.",
            },
            {
              correct: false,
              text: "E + G = L",
              response:
                "Die Summanden dieser Aufgabe und der Ausgangsaufgabe haben keine direkte Beziehung zueinander und somit eignet sich das Wissen über E + G = L nicht zur Herleitung des Ergebnisses der Ausgangsaufgabe.",
            },
          ],
        },
        {
          question: "Auf welche Strategie wird hier zurückgegriffen?",
          answers: [
            {
              correct: false,
              text:
                "Gegensinniges Verändern: Hier werden die beiden Summanden um die gleiche Zahl gegensinnig verändert ohne, dass das Ergebnis sich verändert. Diese Strategie basiert auf dem Konstanzgesetz der Addition",
              response:
                "Beim Gegensinnigen Verändern hätte bei der Aufgabe F + G die hilfreiche Aufgabe wie folgt aussehen müssen: E + H = M. Der erste Summand wäre in diesem Fall um A verkleinert worden und der zweite Summand um A erweitert. Dies ist jedoch nicht der Fall in dem Beispiel.",
            },
            {
              correct: true,
              text:
                "Fasthalbieren: Beim Fasthalbieren wird einer der Summanden mit 1 subtrahiert, um die Aufgabe zunächst zu vereinfachen. Anschließend wird diese 1 wieder addiert.",
              response:
                "In diesem Fall ist G um A größer als F. Somit wird A von G subtrahiert und am Ende dieses A wieder addiert, sodass wir das Ergebnis M erhalten.",
            },
            {
              correct: false,
              text:
                "Hilfsaufgabe: Bei dieser Strategie, wird eine andere, vermeidlich einfachere Aufgabe berechnet und das Ergebnis wird anschließend korrigiert.",
              response:
                "Da sich eine andere Strategie gut zum Berechnen der Aufgabe F + G eignet, ist es nicht von Nöten eine vermeidlich einfachere Aufgabe zu formulieren. Bei anderen Aufgaben ist eine Rechenstrategie vielleicht nicht anwendbar. Dann eignet sich eine Hilfsaufgabe eher.",
            },
          ],
        },
      ],
    ],
  };

  return example;
}

export default createObject();
