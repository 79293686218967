<template>
  <div>
    <h2 class="faledia-caption">Aufbau flexibler Rechenstrategien</h2>
    <v-card outlined>
      <v-card-title>Was sollen die Schüler:innen lernen?</v-card-title>
      <v-card-text>
        Die Kinder können...
        <ul>
          <li>Aufgaben mit Hilfe von Zahl- und Aufgabenbeziehungen lösen</li>
          <li>Strategien auf andere Aufgaben übertragen</li>
          <li>Automatisierte Aufgaben nutzen, um andere Aufgaben zu lösen</li>
          <li>
            Zusammenhänge, Muster und Strukturen für vorteilhaftes Rechnen
            nutzen
          </li>
          <li>
            Zählende Rechenstrategien zugunsten flexibler Rechenstrategien
            abbauen
          </li>
        </ul>
      </v-card-text>
    </v-card>
    <br />
    <p>
      Zu Beginn dieses Kapitels wird Ihnen die Möglichkeit gegeben, die
      Perspektive von Schüler:innen einzunehmen, die sich noch nicht sicher im
      kleinen Einspluseins bewegen. Das Aufbauen von Strategien, um sich
      Rechnungen zu vereinfachen, ist eine anspruchsvolle Anforderung, welche
      Sie im Folgenden nachvollziehen können. Dafür werden die uns bekannten
      Zahlen hier mit den Buchstaben des Alphabets ersetzt.
    </p>
    <div class="image">
      <AppExpandableImage
        contain
        :src="require('@/assets/nzr/Rechenstrategien.png')"
        parentId="nzr"
        elementId="nzr_rechenstrategien.png"
      >
      </AppExpandableImage>
    </div>
    <p>
      Für das Nachvollziehen der Schwierigkeiten von Schüler:innen, ist es
      wesentlich, dass Sie die Buchstaben nicht vorab in Zahlen übersetzen,
      sondern versuchen in den gegebenen Buchstaben zu ‚denken‘.
    </p>
    <div style="background-color: white">
      <LernbausteinStempeln
        :id="LBSTStempeln[0].id"
        :workOrder="LBSTStempeln[0].workOrder"
        :image="LBSTStempeln[0].image"
        :audio="LBSTStempeln[0].audio"
        :elements="LBSTStempeln[0].elements"
      >
        <template v-slot:alternative-content-0>
          <p>
            Zu der ersten Menge C (hier blau) eine zweite Menge L (hier rot)
            hinzuzufügen, erfordert die Kompetenz bereits zu wissen, welche
            Mengen hinter den Symbolen stecken.
          </p>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a0.png')"
            parentId="nzr"
            elementId="nzr_schipper_a0.png"
          >
          </AppExpandableImage>
          <p>
            Werden beide Mengen nun zusammengeschoben, kann die Gesamtanzahl z.
            B. durch Weiterzählen – hier Weiterbuchstabieren – ermittelt werden.
          </p>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a1.png')"
            parentId="nzr"
            elementId="nzr_schipper_a1.png"
          >
          </AppExpandableImage>
        </template>
        <template v-slot:alternative-content-1>
          <p>
            Ist die Summe einer Aufgabe bekannt, können nun Beziehungen unter
            den Mengen genutzt werden, um andere Aufgaben strategisch
            abzuleiten. Durch das gegensinnige Verändern beider Summanden, weiß
            man direkt schon die Summe O für weitere Aufgaben, z. B. der Aufgabe
            D + K. Hierbei wird der erste Summand um A auf D erhöht und der
            zweite Summand um A verkleinert, sodass K entsteht. Die Summe dieser
            Aufgabe muss der Summe von C + D = O entsprechen.
          </p>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a2.png')"
            parentId="nzr"
            elementId="nzr_schipper_a2.png"
          >
          </AppExpandableImage>
        </template>
        <template v-slot:alternative-content-2>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a3.png')"
            parentId="nzr"
            elementId="nzr_schipper_a3.png"
          >
          </AppExpandableImage>
          <p>
            Das Ergebnis von F + G ist nur um A größer als F + F. Die
            Verdopplungsaufgabe F + F ergibt L.
          </p>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a4.png')"
            parentId="nzr"
            elementId="nzr_schipper_a4.png"
          >
          </AppExpandableImage>
          <p>
            Da dafür für von dem G ein A abgezogen wurde, muss es nun wieder
            addiert werden und man erhält die Rechnung L + A = M.
          </p>
          <AppExpandableImage
            contain
            :src="require('@/assets/nzr/Schipper_a5.png')"
            parentId="nzr"
            elementId="nzr_schipper_a5.png"
          >
          </AppExpandableImage>
          <p>
            Die Aufgabe lässt sich also mit der Strategie „Fasthalbieren“ zu
            lösen.
          </p>
        </template>
        <template v-slot:alternative-content>
          <p>
            Zu der ersten Menge C (hier blau) eine zweite Menge L (hier rot)
            hinzuzufügen, erfordert die Kompetenz bereits zu wissen, welche
            Mengen hinter den Symbolen stecken.
          </p>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a0.png')"
              parentId="nzr"
              elementId="nzr_schipper_0.png"
            >
            </AppExpandableImage>
          </div>
          <p>
            Werden beide Mengen nun zusammengeschoben, kann die Gesamtanzahl z.
            B. durch Weiterzählen – hier Weiterbuchstabieren – ermittelt werden.
          </p>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a1.png')"
              parentId="nzr"
              elementId="nzr_schipper_1.png"
            >
            </AppExpandableImage>
          </div>
          <p>
            Ist die Summe einer Aufgabe bekannt, können nun Beziehungen unter
            den Mengen genutzt werden, um andere Aufgaben strategisch
            abzuleiten. Durch das gegensinnige Verändern beider Summanden, weiß
            man direkt schon die Summe O für weitere Aufgaben, z. B. der Aufgabe
            D + K. Hierbei wird der erste Summand um A auf D erhöht und der
            zweite Summand um A verkleinert, sodass K entsteht. Die Summe dieser
            Aufgabe muss der Summe von C + D = O entsprechen.
          </p>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a2.png')"
              parentId="nzr"
              elementId="nzr_schipper_2.png"
            >
            </AppExpandableImage>
          </div>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a3.png')"
              parentId="nzr"
              elementId="nzr_schipper_3.png"
            >
            </AppExpandableImage>
          </div>
          <p>
            Das Ergebnis von F + G ist nur um A größer als F + F. Die
            Verdopplungsaufgabe F + F ergibt L.
          </p>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a4.png')"
              parentId="nzr"
              elementId="nzr_schipper_4.png"
            >
            </AppExpandableImage>
          </div>
          <p>
            Da dafür für von dem G ein A abgezogen wurde, muss es nun wieder
            addiert werden und man erhält die Rechnung L + A = M.
          </p>
          <div class="schipperImage">
            <AppExpandableImage
              contain
              :src="require('@/assets/nzr/Schipper_a5.png')"
              parentId="nzr"
              elementId="nzr_schipper_5.png"
            >
            </AppExpandableImage>
          </div>
          <p>
            Die Aufgabe lässt sich also mit der Strategie „Fasthalbieren“ zu
            lösen.
          </p>
        </template>
      </LernbausteinStempeln>
    </div>
    <p>
      Wie Sie nun vielleicht erfahren haben, ist das Finden von Strukturen und
      Zusammenhängen in ungewohnten Denkmustern durchaus anspruchsvoll. Das
      Nutzen mathematischer Muster und Strukturen stellt allerdings eine
      wesentliche Voraussetzung für geschicktes und flexibles Rechnen dar.
      Schüler:innen müssen sich dafür von einer isolierten Betrachtung einzelner
      Aufgaben und deren Ergebnissen lösen, um entstehende Einsichten für die
      Bearbeitung von weiteren Aufgaben nutzen zu können. Somit ermöglicht das
      Üben eines struktur-fokussierenden Blicks die Aneignung flexibler
      Rechenstrategien (Häsel-Weide, 2016). Daher “reicht [es] nicht aus,
      [Kinder] zu disziplinierten Rechnern zu erziehen. Vielmehr geht es darum,
      bei allen Kindern flexible Rechenkompetenzen zu entwickeln”
      (Rathgeb-Schnierer, Rechtsteiner, 2018, S. IX)
    </p>
    <p>
      Natürlich ist es zu Beginn des Lernens nicht notwendig, z. B. alle 121
      Aufgaben des Einspluseins auswendig zu lernen. Allerdings werden einige
      Aufgaben bereits früh von den Lernenden automatisiert und können ohne
      weiteres Rechnen abgerufen werden. Diese besonders einprägsamen Aufgaben
      werden als Kernaufgaben bezeichnet (eine Übersicht finden Sie im Modul
      <a href="/einspluseins/hintergrundwissen/aufgabenbeziehungen"
        >Einspluseinstafel</a
      >).
    </p>
    <p>
      Mit den Kernaufgaben werden bereits 85 der 121 Aufgaben des Einspluseins
      abgedeckt. Um die 36 fehlenden Aufgaben zu berechnen, können
      Zahlbeziehungen ausgenutzt werden, indem Ableitungsstrategien zum Einsatz
      kommen. Durch die Verwendung herausfordernder Aufgaben und einem
      struktur-fokussierenden Blick können die Lernenden diese Strategien
      erlernen und sich somit vom zählenden Rechnen lösen (Häsel-Weide et al.,
      2014). Walter (2018) führt folgende nicht-zählende Rechenstrategien der
      Addition (Zahlenraum 20) wie folgt zusammen:
    </p>
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Tauschaufgabe</strong></td>
            <td>
              Nach dem Kommutativgesetz lassen sich die Summanden vertauschen.
              Die Anzahl der zu lernenden Aufgaben reduziert sich somit auf 18.
            </td>
            <td>4+5 = 5+4</td>
          </tr>
          <tr>
            <td><strong>Nachbaraufgaben</strong></td>
            <td>
              Einer der Summanden einer Kernaufgabe wird um 1 vergrößert oder
              verringert, um die gesuchte Aufgabe ableiten zu können.
            </td>
            <td>9+8, wird abgeleitet aus 10+8</td>
          </tr>
          <tr>
            <td><strong>Partneraufgaben</strong></td>
            <td>
              Beide Summanden werden gegensinnig verändert. Nach dem
              Konstanzgesetz der Addition bleibt die Summe konstant.
            </td>
            <td>7+6=8+5</td>
          </tr>
          <tr>
            <td><strong>Analogieaufgaben</strong></td>
            <td>
              Ein Summand wird um einen bestimmten Wert erhöht, wodurch sich
              auch die Summe einer Kernaufgabe um einen bestimmten Wert erhöht.
            </td>
            <td>7+5, wird zu 17+5, dadurch ist die Summe, um 10 größer.</td>
          </tr>
          <tr>
            <td><strong>Schrittweises Rechnen</strong></td>
            <td>
              Einer der beiden Summanden wird mithilfe des Assoziativgesetzes
              zerlegt und die Teilsummanden werden einzeln hinzuaddiert.
            </td>
            <td>7+5=(7+3)+2</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p>(vgl. z. B. Walter, 2018; Padberg & Benz, 2021)</p>
    <p>
      Diese Strategien sind geeignet, um die mathematischen Muster und
      Strukturen stärker in den Blick zu nehmen. Da beim zählenden Rechnen die
      einzelne Aufgabe separat, anstatt in Zusammenhang mit anderen Aufgaben
      bearbeitet wird, können diese Strategien mitunter nicht entwickelt werden.
      Gleichzeitig können ohne diese mentalen Werkzeuge Zusammenhänge nicht für
      vorteilhaftes Rechnen genutzt werden, was zur Folge haben kann, dass die
      Kinder im zählenden Rechnen verhaftet bleiben (Schulz, 2014). Daher ist es
      wichtig, den Umgang damit explizit zu thematisieren und zu üben.
    </p>
    <p>
      Nicht nur in der Addition lässt sich von Rechenstrategien zum Vereinfachen
      von komplexeren Aufgaben Gebrauch machen. Auch unter anderem beim
      Subtrahieren können Kinder verschiedene Strategien nutzen, um
      Subtraktionsaufgaben zu lösen (Padberg & Benz, 2021). Im Folgenden sind
      Sie aufgefordert vier der oben tabellarisch aufgeführten Strategien auf
      die Subtraktion zu übertragen und Aufgaben entsprechen zu gruppieren.
    </p>
    <div style="background-color: white">
      <LernbausteinGruppen
        :id="LBSTGruppen[0].id"
        :checkAfterHowManyItems="LBSTGruppen[0].checkAfterHowManyItems"
        :checkAfterExactItemCount="LBSTGruppen[0].checkAfterExactItemCount"
        :showGroupNames="LBSTGruppen[0].showGroupNames"
        :workOrder="LBSTGruppen[0].workOrder"
        :workOrderImage="LBSTGruppen[0].workOrderImage"
        :possibleCategories="LBSTGruppen[0].possibleCategories"
        :elements="LBSTGruppen[0].elements"
        :responses="LBSTGruppen[0].responses"
      >
        <template v-slot:alternative-content>
          <v-simple-table>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td><strong>Analogieaufgaben</strong></td>
                  <td>17-5 = (7-5) +10</td>
                  <td>14-2 = (4-2) +10</td>
                  <td>
                    Die Subtraktion mit Zahlen größer 10 kann für Kinder als
                    Herausforderung gelten. Aus diesem Grund werden in den
                    Beispielen die Aufgaben vereinfacht, indem bei den
                    zweistelligen Minuenden die Zehner abgezogen werden, sodass
                    zunächst eine einfache Subtraktionsaufgaben mit zwei Zahlen
                    unter 10 durchgeführt werden kann. Anschließend wird der
                    zuvor abgezogene Zehner wieder zu dem Ergebnis hinzuaddiert.
                  </td>
                </tr>
                <tr>
                  <td><strong>Nachbaraufgaben</strong></td>
                  <td>9-5 = (10-5) -1</td>
                  <td>6-5 = (5-5) +1</td>
                  <td>
                    In diesen beiden Fällen wird der Minuend um eins verändert
                    durch eine Addition mit 1 oder einer Subtraktion. Durch
                    diese Veränderungen wird in diesen Aufgaben zunächst auf
                    eine Kernaufgabe mit 5 zurückgegriffen. Bei einer
                    Erweiterung wird dann anschließend die 1 subtrahiert und bei
                    einer Subtraktion mit 1 wird anschließend die 1 wieder
                    addiert.
                  </td>
                </tr>
                <tr>
                  <td><strong>Schrittweise Rechnen</strong></td>
                  <td>12-6 = (12-2) -4</td>
                  <td>14-8 = (14-4) -4</td>
                  <td>
                    Hier werden die jeweils Subtrahenden in Schritte zerlegt,
                    sodass hier immer ein glatter Zehner als Zwischenergebnis
                    erreicht wird.
                  </td>
                </tr>
                <tr>
                  <td><strong>Umkehraufgaben</strong></td>
                  <td>12-8 = 8+__=12</td>
                  <td>8-5 = 5+__= 8</td>
                  <td>
                    Hier wird eine Subtraktionsaufgabe in eine Additionsaufgabe
                    verwandelt. Additionsaufgaben bieten sich für viele Kinder
                    an, um eine Aufgabe leichter zu lösen. Der Minuend wird in
                    diesen Fällen zum Ergebnis der Umkehraufgabe und der
                    Subtrahend zu dem ersten Summanden. Das Ergebnis der
                    Ausgangsaufgabe ergibt sich dann aus dem fehlenden zweiten
                    Summanden der Additionsaufgabe.
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </LernbausteinGruppen>
    </div>
    <AppLiteraturNZR />
    <AppBottomNavNZR
      back="/nicht-zaehlendes-rechnen/hintergrundwissen/teil-ganzes-verstaendnis"
      next="/nicht-zaehlendes-rechnen/uebergang"
    />
  </div>
</template>

<script>
import AppBottomNavNZR from "@/common/AppBottomNavNZR";
import AppExpandableImage from "@/common/AppExpandableImage";
import AppLiteraturNZR from "@/common/AppLiteraturNZR";
import LernbausteinStempeln from "@/components/faledia/explorativeElemente/LernbausteinStempeln";
import LernbausteinGruppen from "@/components/faledia/explorativeElemente/LernbausteinGruppen";

import Schipper from "@/components/faledia/seiten/nichtZaehlendesRechnen/LBST/LBST_Stempeln_Schipper";
import Gruppen_Subtraktion from "@/components/faledia/seiten/nichtZaehlendesRechnen/LBST/LBST_Gruppen_Subtraktion";

export default {
  components: {
    AppBottomNavNZR,
    LernbausteinStempeln,
    LernbausteinGruppen,
    AppExpandableImage,
    AppLiteraturNZR,
  },
  data: () => ({
    LBSTStempeln: [Schipper],
    LBSTGruppen: [Gruppen_Subtraktion],
  }),
};
</script>

<style scoped>
.image {
  max-width: 30%;
  margin-inline: auto;
}
.schipperImage {
  max-width: 550px;
  margin-inline: auto;
}
</style>
